var render = function render(){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"input",class:{
    'input--filled': !!_vm.value,
    'input--error': !!_vm.error,
    'input--icon-left': _vm.iconPosition === 'left',
    'input--icon-right': _vm.iconPosition === 'right',
    'input--disabled': _vm.disabled,
    'input--no-arrows': _vm.noArrows,
    'input--white': _vm.white,
  }},[(_vm.title)?_c('span',{staticClass:"input__title"},[_c('span',[_vm._v(_vm._s(_vm.title))]),(_vm.required)?_c('span',{staticClass:"input__required"},[_vm._v("*")]):_vm._e()]):_vm._e(),_c('span',{staticClass:"input__container"},[(_vm.iconPosition)?_c('button',{staticClass:"input__search",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('action')}}},[_vm._t("icon",function(){return [_c('IconComponent',{attrs:{"name":"search"}})]})],2):_vm._e(),_c('input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.mask),expression:"mask"}],ref:"input",staticClass:"input__field",attrs:{"type":_vm.type,"placeholder":_vm.placeholder,"min":_vm.min,"max":_vm.max,"accept":_vm.accept || '',"disabled":_vm.disabled},domProps:{"value":_vm.value},on:{"keydown":function($event){return _vm.$emit('keydown', $event)},"input":_vm.handleInput,"focus":_vm.focus,"blur":function($event){return _vm.$emit('blur', $event)}}}),(_vm.$slots.action)?_c('button',{staticClass:"input__action",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('submit')}}},[_vm._t("action")],2):_vm._e(),_vm._t("default")],2),(_vm.message)?_c('span',{staticClass:"input__message"},[_vm._v(_vm._s(_vm.message))]):_vm._e(),(_vm.error)?_c('span',{staticClass:"input__error"},[_vm._v(_vm._s(_vm.error))]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }