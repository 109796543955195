<template>
  <div class="default-modal map-modal">
    <div class="default-modal__body map-modal__body">
      <button class="btn map-modal__close" @click="$emit('close')">Закрыть</button>
      <div class="map-modal__map">
        <ClientOnly v-if="mapReady">
          <yandex-map ref="map" :settings="settings" :coords="coords" zoom="14" :controls="['zoomControl']">
            <ymap-marker
              ref="marker"
              :icon="icon"
              :coords="coords"
              :properties="properties"
              marker-id="123"
            />
          </yandex-map>
        </ClientOnly>
      </div>
    </div>
  </div>
</template>

<script>
import { loadYmap, yandexMap, ymapMarker } from "vue-yandex-maps";
import ClientOnly from "vue-client-only";

export default {
  name: "MapModal",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      search: "",
      mapReady: false,
      debounceInterval: null,
      debounceTimeout: null,
      coords: [42.89049523595949, 47.63682007602924],
      marker: null,
      map: null,
      text: "Курьерский адрес",
      text2: "Курьерский адр4334ес",
      settings: {
        apiKey: "4f77a55a-eec1-4f77-961f-42cd4e50b13c",
        lang: "ru_RU",
        coordorder: "latlong",
        version: "2.1",
      },
      icon: {
        layout: "default#image",
        imageHref: "/static/images/map-red-location.png",
        imageSize: [40, 56],
        imageOffset: [-18, -56],
        balloonImageSize: [0, 0],
      },
    };
  },
  computed: {
    properties() {
      return {
        balloonContentHeader: "Курьерский адрес",
        balloonContentBody: this.data.address,
      };
    },
  },
  mounted() {
    this.initMap();
    this.debounceInterval = setInterval(() => {
      window.addEventListener("resize", () => {
        this.mapReady = false;
        this.$nextTick(() => {
          this.initMap();
        });
      });
    }, 500);
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }
    this.debounceTimeout = setTimeout(() => {
      this.$nextTick(() => {
        if (this.$refs.map) {
          this.$refs.map.myMap.balloon.open(
            this.coords,
            `<h4 class="map-modal__balloon-title">Клиентский адрес</h4>
            <span class="map-modal__balloon-subtitle">${this.data.address}</span>` // на 2 день это у меня получилось и еще пол дня прошло, что бы я додумался запихнуть html, а не просто текст
            // { closeButton: false }
          ); // Открыть балун на метке
        }
      });
    }, 500);
  },
  methods: {
    initMap() {
      loadYmap(this.settings).then(() => {
        window.ymaps.ready(() => {
          window.ymaps.geocode(this.data.address).then((res) => {
            this.coords = res.geoObjects.get(0).geometry.getCoordinates();
            this.mapReady = true;
          });
        });
      });
    },
  },
  components: { yandexMap, ymapMarker, ClientOnly },
};
</script>

<style lang="stylus">
.map-modal {

  &.default-modal {
    width 100%
    max-width 1000px
    overflow hidden
    border-radius 30px
    padding 0
    +below(680px) {
      padding 20px
    }
  }

  &__body {
    position: relative;
  }

  & &__close {
    position absolute
    right 16px
    top 16px
    z-index 1
    font-size 1rem
    line-height 22px
    box-shadow 0 0 20px rgba(0,0,0,.2)
    padding 4px 12px 6px
    background #fff
    border-radius 24px
    border none
    cursor pointer
  }

  & &__test {
    position absolute
    left 16px
    top 16px
    z-index 1
    font-size 1rem
    line-height 22px
    box-shadow 0 0 20px rgba(0,0,0,.2)
    padding 4px 12px 6px
    background #fff
    border-radius 24px
    border none
    cursor pointer
  }

  &__map {
    width 100%
    height 650px

    .ymap-container {
      width 100%
      height 100%
      border-radius: var(--big-radius)
      overflow hidden
    }
  }

  &__balloon-title {
    font-weight 700
    line-height 24px
    margin-bottom 4px
  }

  &__balloon-subtitle {
    font-size 1rem
    font-weight 400
    line-height 22px
  }

  .ymaps-2-1-79-balloon {
    box-shadow none

    .ymaps-2-1-79-balloon__layout {
      box-shadow: 0 0 20px rgba(0,0,0,.1);
      //box-shadow 0 0 20px rgb(0, 0, 0, .1)
      border-radius 8px
    }
  }

}
</style>
