<template>
  <div class="header-main">
    <div
      :class="{
        'header-main__bg': catalogOpen,
      }"
      @click="toggleCatalog"
    />
    <div class="container-padding">
      <div class="header-main__inner">
        <div class="header-main__content">
          <router-link class="header-main__logo" :to="{ name: 'home' }">
            <IconComponent name="logo" />
          </router-link>
          <button
            @click="toggleCatalog"
            :class="{
              'btn--green-dark': catalogOpen,
            }"
            class="btn btn--main btn--lg desktop"
          >
            <IconComponent name="menu" v-if="!catalogOpen" />
            <IconComponent name="catalog_close" v-else />
            Каталог
          </button>
          <InputComponent @input="searchInput" v-model="search" iconPosition="left" placeholder="Я ищу..." />
          <ShowAddressComponent />
          <div class="header-main__buttons">
            <router-link
              :to="{ name: 'user', params: { link: 'wishlist' } }"
              class="header-main__button desktop"
            >
              <IconComponent name="favour" />
              <span v-if="favoritesLength" class="header-main__button-count">{{ favoritesLength }}</span>
            </router-link>
            <router-link :to="{ name: 'cart' }" class="header-main__button">
              <IconComponent name="basket" />
              <span v-if="cartItemsLength" class="header-main__button-count">{{ cartItemsLength }}</span>
            </router-link>
            <router-link v-if="!user" :to="{ name: 'user' }" class="desktop header-main__button btn">
              <IconComponent name="user" />
            </router-link>
            <router-link v-if="user" :to="{ name: 'user' }" class="desktop btn btn--lg btn--main">
              <IconComponent name="user-success" />
              <span v-if="user && user.name">{{ user.name }}</span>
              <span v-else>Профиль</span>
            </router-link>
            <router-link :to="{ name: 'user' }" class="mobile header-main__button">
              <IconComponent name="user" />
            </router-link>
            <button @click.prevent="showMenu" class="mobile header-main__button btn">
              <IconComponent name="mobile_menu" />
            </button>
          </div>
        </div>
        <div :class="{ 'header-main__dropdown--visible': catalogOpen }" class="header-main__dropdown">
          <CatalogComponent />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import InputComponent from "components/inputs/InputComponent.vue";
import SidenavMenu from "components/menus/components/SidenavMenu.vue";
import ShowAddressComponent from "components/ShowAddressComponent.vue";
import { logout } from "@/utils";
import CatalogComponent from "components/CatalogComponent.vue";

export default {
  name: "HeaderMainComponent",
  data() {
    return {
      offsetTop: 0, // отступ сверху для выпадающего подменю
      search: "",
      catalogOpen: false,
    };
  },
  watch: {
    catalogOpen(val) {
      if (val === true) {
        document.body.classList.add("no-overflow");
      } else {
        document.body.classList.remove("no-overflow");
      }
    },
    "$route.name"() {
      this.hideBg();
    },
    "$route.params"() {
      this.hideBg();
    },
  },
  computed: {
    cartItemsLength() {
      return this.$store.state.auth.cart && this.$store.state.auth.cart.length
        ? this.$store.state.auth.cart.length
        : 0;
    },
    favoritesLength() {
      return this.$store.state.auth.favorites_ids?.length;
    },
    // currentAddress() {
    //   let addresses = this.$store.state.auth.addresses;
    //   return addresses && addresses.length ? addresses[addresses.length - 1]?.address : null;
    // },
    categories() {
      return this.$store.state.global.categories.filter((i) => i.childs && i.childs.length) || [];
    },
    user() {
      return this.$store.state.auth.user;
    },
    isRegistered() {
      return !!this.$store.state.apollo_token || this.$store.state.auth.user;
    },
  },
  methods: {
    logout,
    toggleCatalog() {
      this.catalogOpen = !this.catalogOpen;
    },
    hideBg() {
      this.catalogOpen = false;
    },
    searchInput(title) {
      if (title) {
        if (this.inputDebounceTimeout) {
          clearTimeout(this.inputDebounceTimeout);
        }
        this.loading = true;
        this.inputDebounceTimeout = setTimeout(() => {
          this.$router.push({ name: "search", query: { text: this.search } });
        }, 500);
      } else {
        this.$router.push({ name: "home" });
      }
    },
    showMenu() {
      this.$store.state._menus.push({
        component: SidenavMenu,
        options: {},
        alignRight: true,
      });
    },
  },
  components: { CatalogComponent, ShowAddressComponent, InputComponent, IconComponent },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/svg.styl"
@import "~@/styles/mixins/maxlines.styl"
.header-main {
  padding: 15px 0;
  background var(--white)
  border-bottom: 1px solid var(--gray-dark);
  +below(1024px){
    padding-bottom 30px
  }
  +below(480px) {
    padding 20px 0 30px
  }

  &__bg {
    position absolute
    left 0
    top 132px
    width 100%
    height 100%
    z-index 998
    background rgba(22,22,22,.5)
  }

  &__inner {
    position relative
  }

  &__dropdown {
    position: absolute;
    left 0
    right 0
    top 100%
    z-index 999
    visibility hidden
    opacity: 0;
    transition var(--transition)
    background var(--white)
    box-shadow: 0 40px 50px rgba(0, 0, 0, 0.15);
    border-radius: 0 0 32px 32px;
    overflow hidden
    max-height calc(100vh - 150px)
    display flex
    flex-direction column

    &--visible {
      visibility visible
      opacity 1
    }
  }

  &__content {
    display grid
    gap 15px
    align-items: center;
    grid-template-columns: auto auto 1fr auto auto;
    .input{
      width 100%
    }
    .btn--main{
      margin-left: 15px;
      padding: 0 20px;
    }
    .logo-icon{
      height: 24px;
      //padding-right: 15px;
      path{
        fill var(--red) !important
      }
    }
    +below(1024px){
      grid-template-columns 1fr 1fr
      .input{
        width: 100%;
        order 5
        grid-area: 2 / 1 / 3 / 3;
        .logo-icon{
          height: 23px;
          padding-right: 15px;
          path{
            fill var(--red) !important
          }
        }
      }
      .address-show {
        display none
      }
    }
    +below(480px) {
      row-gap 10px
    }
  }

  &__logo {
    display flex
    align-items center
    width 200px
    height auto

    .icon {
      width 100%
    }
  }

  &__location {
    display flex
    justify-content: space-around;
    gap 15px
    cursor pointer
    background: var(--white);
    border: 1px solid var(--gray-dark);
    border-radius: 15px;
    border-right none
    height: 60px;
    align-items: center;
    padding 0 0 0 22px
    max-width 302px

    &-title {
      color: var(--dark-light);
      max-width 112px
      text-align left
      font-size 16px
      maxlines(1)
    }

    .icon {
      width: 20px
      height: 20px
    }

    &-right{
      display flex
      flex-direction column;
      background: var(--gray);
      height: 60px;
      border: 1px solid var(--gray-dark);
      border-radius: 15px;
      justify-content: center;
      padding 0 15px;

      &_title{
        font-weight: 400;
        font-size: 0.875em;
        line-height: 20px;
        color: var(--dark);
        margin 0
      }

      &_subtitle{
        font-weight: 400;
        font-size: 0.625em;
        line-height: 12px;
        color: var(--dark);
      }
    }
  }

  &__buttons{
    display flex
    +below(1024px){
      justify-content: flex-end;
    }
    .icon {
      max-height: 24px;
      max-width: 24px;

      path {
        transition var(--transition)
      }
    }
  }

  &__button {
    display flex
    align-items center
    justify-content center
    cursor pointer
    width: 60px
    height: 60px
    background none
    border none

    &:hover .icon svg path {
      fill var(--main)
    }

    &-count {
      display inline-flex
      align-items center
      justify-content center
      text-align center
      absolute right 10px top 10px
      height 20px
      min-width 14px
      background: var(--red);
      border: 2px solid var(--bg-color);
      box-sizing: border-box;
      border-radius: 100px
      font-size: 0.875em
      line-height 10px
      color: var(--white);
      padding 4px
      +below(540px) {
        display none
      }
    }
  }
}
</style>
