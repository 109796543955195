<template>
  <div class="header-top">
    <div class="container-padding">
      <div class="header-top__inner">
        <nav class="header-top__nav">
          <ul>
            <li v-for="(item, i) in navLeft" :key="i">
              <router-link :to="item.link">{{ item.title }}</router-link>
            </li>
          </ul>
        </nav>
        <nav class="header-top__nav">
          <ul>
            <li v-for="(item, i) in nav" :key="i">
              <router-link :to="item.link">{{ item.title }}</router-link>
            </li>
          </ul>
        </nav>
        <a v-if="phone" :href="phone | phone">
          <IconComponent name="phone" />
          {{ phone }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
export default {
  name: "HeaderTopComponent",
  components: { IconComponent },
  data() {
    return {
      navLeft: [
        {
          title: "Вопросы и ответы",
          link: { name: "faq" },
        },
        {
          title: "Акции",
          link: { name: "shares" },
        },
      ],
    };
  },
  computed: {
    nav() {
      let types = this.$store.state._types;

      return [
        {
          title: "Вакансии",
          link: { name: "vacancies" },
        },
        {
          title: "Помощь",
          link: { name: "document", params: { id: types.HELP } },
        },
        {
          title: "Для бизнеса",
          link: { name: "document", params: { id: types.FOR_BUSINESS } },
        },
      ];
    },
    phone() {
      return this.$store.state.global.feedback?.phone;
    },
  },
};
</script>

<style lang="stylus">
.header-top {
  background var(--white)
  &__inner{
    display flex
    justify-content space-between
    padding-top 15px
  }
  &__nav{
    ul{
      display flex
      gap 30px
    }
  }
  a{
    display flex
    align-items: center;
    gap 7px
    font-weight: 400;
    font-size: 1em;
    line-height: 26px;
    color: var(--gray_light);
    opacity .6
    svg{
      max-width: 16px;
      max-height: 16px;
      path{
        fill var(--gray_light)
      }
    }
  }
}
</style>
