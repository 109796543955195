<template>
  <div class="default-menu sidenav-menu">
    <div class="default-menu__header">
      <div class="default-menu__header-top">
        <button type="button" class="default-menu__close" @click="$emit('close')">
          <IconComponent name="close" />
        </button>
      </div>
    </div>
    <div class="default-menu__body sidenav-menu__body">
      <ShowAddressComponent />
      <ul class="default-menu__btns">
        <li>
          <router-link :to="{ name: 'user', params: { link: 'wishlist' } }" class="btn btn--gray btn--lg">
            <IconComponent name="favour" />
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'cart' }" class="btn btn--gray btn--lg">
            <IconComponent name="basket" />
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'user' }" class="btn btn--gray btn--lg">
            <IconComponent name="user" />
          </router-link>
        </li>
      </ul>
      <ul class="default-menu__links">
        <li v-for="(item, i) in list" :key="i">
          <template v-if="item.sublinks && item.sublinks.length">
            <a
              href="#"
              @click.prevent="setActive(item)"
              class="default-menu__link"
              :class="{ 'default-menu__link--active': isActive(item) }"
            >
              <span>{{ item.title }}</span>
              <IconComponent name="arrow_right-1" />
            </a>
            <ul class="default-menu__links sublinks">
              <li v-for="(sub, k) in item.sublinks" :key="k">
                <router-link
                  :to="{
                    name: 'catalog',
                    params: { id: sub.childs && sub.childs.length ? sub.childs[0].id : null },
                  }"
                  class="default-menu__link"
                >
                  <span>{{ sub.title }}</span>
                  <IconComponent name="arrow_right-1" />
                </router-link>
              </li>
            </ul>
          </template>
          <router-link v-else :to="item.route" class="default-menu__link">
            <span>{{ item.title }}</span>
            <IconComponent name="arrow_right-1" />
          </router-link>
        </li>
      </ul>
    </div>
    <div class="default-menu__footer sidenav-menu__footer">
      <a v-if="phone" :href="phone | phone">
        <IconComponent name="phone" />
        {{ phone }}
      </a>
    </div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import ShowAddressComponent from "components/ShowAddressComponent.vue";

export default {
  name: "SidenavMenu",
  data() {
    return {
      active: null,
    };
  },
  computed: {
    list() {
      let types = this.$store.state._types;

      return [
        {
          title: "Каталог",
          route: { name: "catalog" },
          sublinks: this.$store.state.global.categories,
        },
        {
          title: "Вопросы и ответы",
          route: { name: "faq" },
        },
        {
          title: "Вакансии",
          route: { name: "vacancies" },
        },
        {
          title: "Помощь",
          route: { name: "document", params: { id: types.HELP } },
        },
        {
          title: "Для бизнеса",
          route: { name: "document", params: { id: types.FOR_BUSINESS } },
        },
      ];
    },
    phone() {
      return this.$store.state.global.feedback?.phone;
    },
    isRegistered() {
      return !!this.$store.state.apollo_token || this.$store.state.auth.user;
    },
  },
  methods: {
    setActive(item) {
      if (this.isActive(item)) {
        this.active = null;
      } else {
        this.active = item;
      }
    },
    isActive(item) {
      return JSON.stringify(item) === JSON.stringify(this.active);
    },
  },
  components: { ShowAddressComponent, IconComponent },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/maxlines.styl"
</style>
