<template>
  <button :title="currentAddress" class="address-show">
    <div @click="showAddressesModal" class="address-show__left">
      <IconComponent name="location" />
      <span class="address-show__address">{{ currentAddress || "Укажите адрес" }}</span>
    </div>
    <div @click="showDeliveryTermsModal" class="address-show__right">
      <p class="address-show__title">30-35 минут</p>
      <p class="address-show__subtitle">Доставка {{ deliveryPrice }} ₽</p>
    </div>
  </button>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import LoginModal from "components/modals/components/LoginModal.vue";
import DeliveryTermsModal from "components/modals/components/DeliveryTermsModal.vue";
import AddressesModal from "components/modals/components/AddressesModal.vue";

export default {
  name: "ShowAddressComponent",
  components: {
    IconComponent,
  },
  computed: {
    deliveryPrice() {
      return this.$store.state.global.feedback.delivery_price;
    },
    currentAddress() {
      let addresses = this.$store.state.auth.addresses;
      return addresses && addresses.length ? addresses.find((item) => item.is_active)?.address : null;
      // return addresses && addresses.length ? addresses[addresses.length - 1]?.address : null;
    },
    isRegistered() {
      return !!this.$store.state.apollo_token || this.$store.state.auth.user;
    },
  },
  methods: {
    showDeliveryTermsModal() {
      this.$emit("close");
      this.$store.state._modals.push({
        component: DeliveryTermsModal,
      });
    },
    showAddressesModal() {
      this.$emit("close");
      this.$store.state._modals.push({
        component: this.isRegistered ? AddressesModal : LoginModal,
      });
    },
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/maxlines.styl"
.address-show {
  display flex
  justify-content: space-around;
  gap 15px
  cursor pointer
  background: var(--white);
  border: 1px solid var(--gray-dark);
  border-radius: 15px;
  border-right none
  height: 60px;
  align-items: center;
  width 302px
  max-width 302px

  &__left {
    display flex
    gap 15px
    height: 60px;
    align-items: center;
    padding-left 22px
    text-align start
  }

  &__address {
    font-size 1rem
    font-weight 400
    line-height 26px
    color var(--dark-light)
    maxlines(1)
  }

  //&__title {
  //  font-weight 400
  //  color var(--dark-light)
  //  min-width 84px
  //  text-align left
  //  font-size 16px
  //}

  .icon {
    width 20px
    height: 20px
  }

  &__right {
    display flex
    flex-direction column;
    background: var(--gray);
    height: 60px;
    border: 1px solid var(--gray-dark);
    border-radius: 15px;
    align-items flex-start
    justify-content: center;
    padding 0 15px;
    margin-left auto
    min-width 114px
  }

  &__title {
    min-width 84px
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 20px;
    color: var(--dark);
    margin 0
  }

  &__subtitle {
    font-weight: 400;
    font-size: 0.625rem;
    line-height: 12px;
    color: var(--dark);
  }
}
</style>
