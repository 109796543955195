<template>
  <div v-if="suggestions && suggestions.length && focused" class="dadata">
    <ul class="dadata-suggestions">
      <li @click="selectValue(s)" class="dadata-suggestions__item" v-for="(s, i) in suggestions" :key="i">
        {{ s.value }}
      </li>
    </ul>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "DadataComponent",
  props: {
    value: String,
  },
  data() {
    return {
      active: null,
      timeout: null,
      suggestions: [],
      focused: false,
    };
  },
  watch: {
    value() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.get();
      }, 300);
    },
  },
  mounted() {
    // if (this.value) {
    this.get();
    // }
  },
  methods: {
    blur() {
      if (this.focused) {
        this.focused = false;
      }
    },
    focus() {
      if (!this.focused) {
        this.focused = true;
      }
    },
    selectValue(s) {
      this.active = s;
      this.$emit("input", s.value);
      this.$emit("setActive", s);
      this.blur();
    },
    get() {
      return axios
        .post(
          "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address",
          {
            query: this.value || "Республика Дагестан, Махачкала, улица Ермошкина, 17",
          },
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Token aaf06f766b4349e3a4abdaad8a8c107ec9d5870f",
            },
          }
        )
        .then(({ data }) => {
          this.suggestions = data.suggestions || [];
        });
    },
  },
};
</script>

<style lang="stylus">
.dadata {
  position absolute
  top 100%
  left 0
  right 0
  max-height 200px
  overflow auto
  background var(--white)
  z-index 10
  box-shadow 0 20px 60px rgba(0,0,0,0.15)

  &-suggestions {
    &__item {
      padding 5px 10px
      cursor pointer
      font-size 1em

      &:hover {
        background var(--gray)
      }
    }
  }
}
</style>
