<template>
  <div class="delivery-terms default-modal">
    <div class="default-modal__header delivery-terms-modal__header">
      <h3 class="default-modal__title delivery-terms__title">Условия доставки</h3>
      <button type="button" class="default-modal__close" @click="$emit('close')">
        <IconComponent name="close" />
      </button>
    </div>
    <div class="delivery-terms__body default-modal__body">
      <div class="delivery-terms__list">
        <div class="delivery-terms__item">
          <span>Время доставки</span>
          <div class="delivery-terms__line" />
          <span>25-35 минут</span>
        </div>
        <div class="delivery-terms__item">
          <span>Доставка {{ deliveryPrice }} ₽</span>
          <div class="delivery-terms__line" />
          <span>до {{ freeDeliveryPrice }} ₽</span>
        </div>
        <div class="delivery-terms__item">
          <span>Доставка 0 ₽</span>
          <div class="delivery-terms__line" />
          <span>от {{ freeDeliveryPrice }} ₽</span>
        </div>
      </div>
      <div class="delivery-terms__address">
        <h3 class="delivery-terms__title">Адрес магазина</h3>
        <span v-if="address">{{ address }}</span>
      </div>
      <button @click="$emit('close')" class="btn btn--lg btn--main delivery-terms__btn">Хорошо</button>
    </div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";

export default {
  name: "DeliveryTermsModal",
  computed: {
    deliveryPrice() {
      return this.$store.state.global.feedback.delivery_price;
    },
    freeDeliveryPrice() {
      return this.$store.state.global.feedback.free_delivery_price;
    },
    address() {
      return this.$store.state.global.feedback.address;
    },
    // currentAddress() {
    //   let addresses = this.$store.state.auth.addresses;
    //   return addresses && addresses.length ? addresses.find((item) => item.is_active)?.address : null;
    //   // return addresses && addresses.length ? addresses[addresses.length - 1]?.address : null;
    // },
  },
  components: {
    IconComponent,
  },
};
</script>

<style lang="stylus">
.delivery-terms {
  padding 40px !important
  width 600px

  &__body {
    gap 30px !important
  }

  &__title {
    font-size 1.75rem
    font-weight 900
    line-height 34px
    margin-bottom 0
  }

  &__list {
    display flex
    flex-direction column
    gap 15px
    width 100%
  }

  &__item {
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-gap: 15px;
    align-items: baseline;
  }

  &__line {
    height: 1px;
    border: 0.5px dashed var(--dark-light);
    min-width: 30px;
  }

  &__address {
    display flex
    flex-direction column
    gap 15px
  }

  &__btn {
    width 100%
  }
}
</style>
