import axios from "axios";
import AlertModal from "components/modals/components/AlertModal.vue";
let debounceTimeout = null;

export function confirmUser({ store, apollo, data = {}, callback }) {
  store.state.auth.user = data.user;
  store.state.apollo_token = data.token;
  // Сохраняю токен в сессии
  axios({
    method: "post",
    url: "/token",
    data: {
      token: data.token,
    },
    headers: {
      "Content-Type": "application/json",
    },
  }).then(() => {
    // сохраняем товары в корзине из store, куда они добавлются из localStorage в App.vue
    store
      .dispatch("CART_ADD_ITEMS", {
        apollo: apollo,
        variables: {
          items: store.state.auth.cart.map((c) => {
            return {
              id: c.item.id,
              count: c.count,
            };
          }),
        },
      })
      .then(() => {
        store.dispatch("ME", {
          apollo: apollo,
        });
      });
    if (callback) {
      callback();
    }
  });
}

/**
 * Добавление в корзину / изменение количества товаров в корзине
 * @param store
 * @param apollo
 * @param data - продукт или массив продуктов
 * @param count - количество продукта/продуктов в data
 * @param callback
 */
export function addCartItems({ store, apollo, data = {}, count, callback }) {
  let isRegistered = store.state.apollo_token || store.state.auth.user;

  let items = [];
  if (Array.isArray(data)) {
    items = data;
  } else if (typeof data === "object") {
    items = [data];
  }
  // если товара нет в корзине, то добавляем
  items.forEach((item) => {
    // если товар есть в корзине, изменяем количество
    if (inCart({ store, data: item })) {
      store.state.auth.cart.find((c) => c.item.id === item.id).count = count;
    } else {
      // если товара нет, то добавляем
      store.state.auth.cart.unshift({
        count: count,
        item: item,
      });
    }
  });

  // костыль, изменяем массив, чтобы вызвался watch в App.vue
  // и сохранил изменения в localStorage
  store.state.auth.cart.push({});
  store.state.auth.cart.pop();

  if (isRegistered) {
    // создаю структуру для отправки на бэк
    let itemsChanged = items.map((i) => ({
      id: i.id,
      count: count,
    }));

    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(() => {
      store
        .dispatch("CART_ADD_ITEMS", {
          apollo: apollo,
          variables: {
            items: itemsChanged,
          },
        })
        .then(() => {
          if (callback) {
            callback();
          }
        });
    }, 500);
  }
}

function inCart({ store, data }) {
  return data && store.state.auth.cart ? store.state.auth.cart.find((c) => c.item?.id === data?.id) : false;
}

/**
 * Удаление товаров из корзины
 * @param store
 * @param apollo
 * @param data - товар или элемент корзины с товаром, который надо удалить
 * @returns {Promise<void>}
 */
export async function removeCartItems({ store, apollo, ids = [] }) {
  let isRegistered = store.state.apollo_token || store.state.auth.user;
  store.state.auth.cart = store.state.auth.cart.filter((c) =>
    c.id ? !ids.includes(c.id) : !ids.includes(c.item.id)
  );
  if (isRegistered) {
    await store.dispatch("CART_DELETE_ITEMS", {
      apollo: apollo,
      variables: {
        id: ids,
      },
    });
  }
}

/**
 * Выход из аккаунта
 * @param store
 * @param apollo
 * @returns {Promise<void>}
 */
export async function logout({ store, router }) {
  store.state._modals.push({
    component: AlertModal,
    options: {
      title: "Выйти из аккаунта",
      message: "Вы уверены, что хотите выйти из аккаунта?",
      isConfirm: true,
      closeBtnTitle: "Отмена",
      confirmBtnTitle: "Выйти",
      closeBtnClass: "btn--gray",
      confirmBtnClass: "btn--red",
      callback: () => {
        store.state.apollo_token = undefined;
        store.state.auth.user = null;
        axios({
          method: "post",
          url: "/token",
          data: {
            token: null,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }).then(() => {
          if (router.currentRoute.name === "user") {
            router.push({ name: "home" }).then(() => {
              location.reload();
            });
          } else {
            location.reload();
          }
        });
      },
    },
  });
}

/**
 * Вывод ошибок валидации
 * @param graphQLErrors
 */
export function parseGqlErrors(graphQLErrors) {
  graphQLErrors.forEach((err) => {
    if (err.extensions.category === "validation") {
      Object.keys(err.extensions.validation).forEach((key) => {
        if (this.form[key]) {
          this.form[key].error = err.extensions.validation[key][0];
        }
      });
    }
  });
}

/**
 * Убрать ошибки
 */
export function resetErrors(form = "form") {
  Object.keys(this[form]).forEach((key) => {
    this[form][key].error = undefined;
  });
}
