<template>
  <div class="default-modal address-modal">
    <div class="default-modal__header address-modal__header">
      <button v-if="back" type="button" class="default-modal__back" @click="$emit('close')">
        <IconComponent name="arrow-left-2" />
      </button>
      <h2 class="default-modal__title">Укажите адрес</h2>
      <button class="default-modal__close" @click="$emit('closeAll')">
        <IconComponent name="close" />
      </button>
    </div>
    <span class="default-modal__subtitle">В зависимости от адреса, ассортимент может отличаться.</span>
    <div class="default-modal__body">
      <div v-click-outside="blur" class="address-modal__search">
        <InputComponent
          v-model="search"
          iconPosition="left"
          placeholder="Введите адрес"
          class="address-modal__search-input"
          @focus="focus"
          @input="premiseNumber = null"
        >
          <DadataComponent
            @setActive="setActive"
            ref="dadata"
            v-model.trim="search"
            class="address-modal__search-suggestions"
          />
        </InputComponent>
        <button @click="submit" class="btn btn btn--lg btn--main" :disabled="!premiseNumber">Выбрать</button>
      </div>
      <div class="address-modal__map">
        <ClientOnly v-if="mapReady">
          <yandex-map
            :settings="settings"
            :options="options"
            :coords="coords"
            zoom="14"
            :controls="['zoomControl']"
            @click="getLocation"
            @map-was-initialized="init"
          >
            <ymap-marker :icon="icon" :coords="coords" marker-id="123" />
          </yandex-map>
        </ClientOnly>
      </div>
    </div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import { loadYmap, yandexMap, ymapMarker } from "vue-yandex-maps";
import ClientOnly from "vue-client-only";
import InputComponent from "components/inputs/InputComponent.vue";
import DadataComponent from "components/DadataComponent.vue";
import ClickOutside from "vue-click-outside";
import ADDRESS_CREATE from "gql/mutations/AddressCreate.graphql";

export default {
  name: "AddressModal",
  props: {
    back: Boolean,
    // address: String,
  },
  data() {
    return {
      search: "",
      mapReady: false,
      // coords: [42.967631, 47.503442],
      coords: [42.89049523595949, 47.63682007602924],
      marker: null,
      map: null,
      address: null,
      premiseNumber: null,
      settings: {
        apiKey: "4f77a55a-eec1-4f77-961f-42cd4e50b13c",
        lang: "ru_RU",
        coordorder: "latlong",
        version: "2.1",
      },
      icon: {
        layout: "default#image",
        imageHref: "/static/images/map-red-location.png",
        imageSize: [40, 56],
        imageOffset: [-18, -56],
        balloonImageSize: [0, 0],
      },
      options: {
        suppressMapOpenBlock: true,
        suppressObsoleteBrowserNotifier: true,
        yandexMapDisablePoiInteractivity: false,
      },
    };
  },
  created() {
    loadYmap({
      ...this.settings,
    }).then(() => {
      window.ymaps.ready(this.init);
      this.mapReady = true;

      // window.ymaps.ready(() => {
      //   window.ymaps.geocode(m.address).then((res) => {
      //     m.coords = res.geoObjects.get(0).geometry.getCoordinates();
      //   });
      // });
    });
  },
  mounted() {
    if (this.currentAddress) {
      this.search = this.currentAddress;
    }
  },
  computed: {
    currentAddress() {
      let addresses = this.$store.state.auth.addresses;
      return addresses && addresses.length ? addresses.find((item) => item.is_active)?.address : null;
      // return addresses && addresses.length ? addresses[addresses.length - 1]?.address : null;
    },
  },
  methods: {
    focus() {
      this.$refs.dadata.focus();
    },
    blur() {
      this.$refs.dadata.blur();
    },
    setActive(address) {
      this.coords = [address.data.geo_lat, address.data.geo_lon];
      this.getLocation(null, this.coords);
    },
    init(map) {
      this.map = map;
      // поставить обработчик на событие поиска
      // if (this.map.controls) {
      //   this.map.controls
      //     .get("searchControl")
      //     .options.set({ noPlacemark: true, placeholderContent: "Введите адрес доставки" });
      // }
    },
    getLocation(e, coords) {
      this.coords = e ? e.get("coords") : coords;
      // Если метка уже создана – просто передвигаем ее.
      if (this.marker) {
        this.marker.geometry.setCoordinates(this.coords);
      }
      // Если нет – создаем.
      else {
        this.marker = this.createPlacemark(this.coords);
        this.map.geoObjects.add(this.marker);
        // Слушаем событие окончания перетаскивания на метке.
        this.marker.events.add("dragend", () => {
          this.getAddress(this.marker.geometry.getCoordinates());
        });
      }
      this.getAddress(this.coords);
    },
    createPlacemark(coords) {
      return new window.ymaps.Placemark(
        coords,
        {
          iconLayout: "default#image",
        },
        {
          iconLayout: "default#image",
          imageHref: "/static/images/map-red-location.png",
          iconImageSize: [0, 0],
        }
      );
    },
    // Определяем адрес по координатам (обратное геокодирование).
    getAddress(coords) {
      window.ymaps.geocode(coords).then((res) => {
        let firstGeoObject = res.geoObjects.get(0);
        this.premiseNumber = firstGeoObject.getPremiseNumber();
        this.address = firstGeoObject.getAddressLine();
        this.search = this.address;
      });
    },
    submit() {
      if (!this.loading) {
        this.loading = true;
        this.$apollo.provider.defaultClient
          .mutate({
            mutation: ADDRESS_CREATE,
            variables: {
              address: this.search,
            },
            context: {
              headers: {
                Authorization: "Bearer " + this.$store.state.apollo_token,
              },
            },
          })
          .then(({ data }) => {
            this.$store.dispatch("GET_ADDRESSES", {
              apollo: this.$apollo,
            });
            this.loading = false;
            if (data.AddressCreate) {
              // this.$notify({
              //   title: "Успешно",
              //   text: data.AddressCreate.message,
              //   type: "success",
              // });
              this.$emit("close");
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
  },
  directives: {
    ClickOutside,
  },
  components: { DadataComponent, InputComponent, IconComponent, yandexMap, ymapMarker, ClientOnly },
};
</script>

<style lang="stylus">
.address-modal {

  &.default-modal {
    width 100%
    max-width 720px
    //overflow hidden
    border-radius 30px
    padding 50px
    +below(680px) {
      padding 20px
    }
  }

  & &__header {
    display grid
    grid-template-columns auto 1fr auto

    .default-modal__title {
      justify-content flex-start
      text-align center
    }
  }

  &__search {
    display flex
    gap: 20px
    width 100%
    +below(580px) {
      flex-direction column
    }

    &-input {
      flex-grow 1

      .input__action {
        .icon svg path {
          fill var(--dark-light)
        }
      }
    }
  }

  &__map {
    width 100%
    height 372px

    .ymap-container {
      width 100%
      height 100%
      border-radius: var(--big-radius)
      overflow hidden
    }
  }
}
</style>
