<template>
  <div
    :class="{ 'address-component--active': activeAddress }"
    @click="toggleAddress(data)"
    class="address-component"
  >
    <span class="address-component__title">{{ data.address }}</span>
    <div v-click-outside="closePopper" class="address-component__popper-wrapper">
      <button class="btn btn--gray address-component__btn" @click.stop="togglePopper">
        <IconComponent name="more-2-line" />
      </button>
      <div v-if="activePopper" class="address-component__popper">
        <button @click.stop="showMapModal" class="btn address-component__popper-btn">
          Показать на карте
        </button>
        <button
          @click.stop="deleteAddress(data.id)"
          class="btn address-component__popper-btn address-component__popper-btn--remove"
        >
          Удалить
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import ClickOutside from "vue-click-outside";
import MapModal from "components/modals/components/MapModal.vue";

export default {
  name: "AddressComponent",
  components: { IconComponent },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    activeAddress: Boolean,
    activePopper: Boolean,
  },
  methods: {
    togglePopper() {
      if (this.activePopper) {
        this.closePopper();
      } else {
        this.$emit("togglePopper", this.data);
      }
    },
    closePopper() {
      if (this.activePopper) {
        this.$emit("closePopper", this.data);
      }
    },
    toggleAddress(address) {
      this.$emit("toggleAddress", address);
    },
    deleteAddress(id) {
      this.$emit("deleteAddress", id);
      this.active = false;
    },
    showMapModal() {
      this.$store.state._modals.push({
        component: MapModal,
        options: {
          data: this.data,
        },
      });
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style lang="stylus">
.address-component {
  width 100%
  display flex
  justify-content space-between
  gap 15px
  position relative
  padding 12px 16px
  border-radius 16px
  border 1px solid var(--gray-dark)
  cursor pointer

  &--active {
    border-color var(--main)
    cursor default
  }

  &__title {
    display flex
    align-items center
    font-size: 0.875em
    line-height 14px
    color var(--body_muted)
  }

  &__popper-wrapper {
    position relative
    align-self flex-start
    z-index 10
  }

  &__popper {
    position absolute
    padding 4px 8px
    width 156px
    right 100%
    top 50%
    transform translateY(-50%)
    box-shadow 0 8px 24px rgba(149, 157, 165, 0.2)
    border-radius 8px
    z-index 10
    background var(--white)

    &:before {
      position absolute
      content ''
      right -2px
      top 50%
      transform rotate(45deg) translateY(-50%)
      width 12px
      height 12px
      background var(--white)
      pointer-events none
    }
  }

  & &__popper-btn {
    font-size .875rem
    line-height 20px
    padding 8px
    border-radius 8px

    &:hover {
      background var(--gray-2)
    }

    &--remove {
      color var(--red)
      width 100%
      display flex
      justify-content flex-start
    }
  }

  &__btn {
    height 30px
    width 30px

    &:not(&:hover) {
      background transparent
    }

    .icon {
      width 16px
      height 16px
    }
  }
}
</style>
