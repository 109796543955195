<template>
  <div class="main-footer">
    <div class="container-padding">
      <div class="main-footer__inner">
        <div class="main-footer__top">
          <IconComponent name="logo" />
          <div class="main-footer__top_pay-method">
            <span v-for="(item, i) in payMethod" :key="i">
              <IconComponent :name="item" />
            </span>
          </div>
        </div>
        <div class="main-footer__bottom">
          <div class="main-footer__bottom-block">
            <h3 class="main-footer__bottom-block-title">Покупателям</h3>
            <ul class="col-2">
              <li v-for="(item, i) in forShoppers" :key="i">
                <router-link :to="item.link">
                  {{ item.title }}
                  <IconComponent name="arrow_right" />
                </router-link>
              </li>
            </ul>
          </div>
          <div v-if="feedback.phone || feedback.email" class="main-footer__bottom-block">
            <h3 class="main-footer__bottom-block-title">Контакты</h3>
            <ul>
              <li v-if="feedback.phone">
                <a :href="feedback.phone | phone">
                  {{ feedback.phone }}
                  <IconComponent name="arrow_right" />
                </a>
              </li>
              <li v-if="feedback.email">
                <a :href="`mailto:${feedback.email}`">
                  {{ feedback.email }}
                  <IconComponent name="arrow_right" />
                </a>
              </li>
            </ul>
          </div>
          <div v-if="feedback.socials && feedback.socials.length" class="main-footer__bottom-block">
            <h3 class="main-footer__bottom-block-title">Соц. сети</h3>
            <ul>
              <li v-for="(item, i) in feedback.socials" :key="i">
                <a :href="item.link" target="_blank" rel="nofollow">
                  {{ item.title }}
                  <IconComponent name="arrow_right" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
export default {
  name: "MainFooterComponent",
  computed: {
    payMethod() {
      return ["visa", "mastercard", "mir"];
    },
    forShoppers() {
      let types = this.$store.state._types;

      return [
        {
          title: "Как оформить заказ",
          link: { name: "document", params: { id: types.ORDERING_RULES } },
        },
        {
          title: "Помощь",
          link: { name: "document", params: { id: types.HELP } },
        },
        {
          title: "Возврат",
          link: { name: "document", params: { id: types.REFUND } },
        },
        {
          title: "Способы оплаты",
          link: { name: "document", params: { id: types.PAYMENT_METHOD } },
        },
        {
          title: "Сотрудничество",
          link: { name: "document", params: { id: types.COOPERATION } },
        },
        {
          title: "Акции",
          link: { name: "shares" },
        },
      ];
    },
    feedback() {
      return this.$store.state.global.feedback || {};
    },
  },
  components: { IconComponent },
};
</script>

<style lang="stylus">
.main-footer {
  display flex
  flex-direction column

  &__inner {
    padding 0 0 50px 0
    display flex
    flex-direction column
    gap: 30px
    +below(540px) {
      padding 60px 0 30px
    }
  }

  &__top {
    display flex
    justify-content space-between
    padding-bottom 30px
    border-bottom 2px solid var(--gray-dark)
    +below(1024px) {
      flex-wrap wrap
      gap 15px
    }

    &_pay-method {
      display flex
      gap 16px

      span {
        display flex
        align-items center
        justify-content: center;
        background: var(--gray);
        border-radius: 4px;
        width: 51px;
        height: 36px;
      }
      svg{
        max-height 36px
        max-width 51px
      }
    }
  }

  &__bottom {
    display grid
    grid-template-columns 2fr 1fr 1fr
    gap var(--gap)
    justify-content space-between
    +below(990px) {
      grid-template-columns 1fr
    }
  }

  &__bottom-block {
    display flex
    flex-direction column
    width: 100%;

    &-title {
      text-transform none
      font-weight 700
      padding 15px 0
      margin 0
    }

    ul {
      column-gap 30px

      &.col-2 {
        column-count 2
        +below(560px) {
          column-count 1
        }
      }

      a {
        display flex
        justify-content space-between
        width: 100%;
        font-weight: 400;
        font-size: 1em;
        line-height: 26px;
        align-items: center;
        padding 10px 0
        color: var(--dark);
        svg{
          max-height: 10px;
          max-width: 5px;
          path{
            fill var(--dark_light)
          }
        }
      }
    }
  }
}
</style>
