<template>
  <label
    class="input"
    :class="{
      'input--filled': !!value,
      'input--error': !!error,
      'input--icon-left': iconPosition === 'left',
      'input--icon-right': iconPosition === 'right',
      'input--disabled': disabled,
      'input--no-arrows': noArrows,
      'input--white': white,
    }"
  >
    <span class="input__title" v-if="title">
      <span>{{ title }}</span>
      <span class="input__required" v-if="required">*</span>
    </span>
    <span class="input__container">
      <button class="input__search" type="button" v-if="iconPosition" @click="$emit('action')">
        <slot name="icon">
          <IconComponent name="search" />
        </slot>
      </button>
      <input
        :type="type"
        v-mask="mask"
        :placeholder="placeholder"
        class="input__field"
        :min="min"
        :max="max"
        :accept="accept || ''"
        ref="input"
        :value="value"
        @keydown="$emit('keydown', $event)"
        @input="handleInput"
        @focus="focus"
        @blur="$emit('blur', $event)"
        :disabled="disabled"
      />
      <button v-if="$slots.action" class="input__action" type="button" @click.prevent="$emit('submit')">
        <slot name="action" />
      </button>
      <slot @blur="blur" @focus="focus"></slot>
    </span>
    <span v-if="message" class="input__message">{{ message }}</span>
    <span v-if="error" class="input__error">{{ error }}</span>
  </label>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import { VueMaskDirective } from "v-mask";

export default {
  name: "InputComponent",
  props: {
    title: String,
    message: String,
    mask: String,
    type: {
      type: String,
      default() {
        return "text";
      },
    },
    numbersOnly: {
      type: Boolean,
    },
    placeholder: {
      type: [String, Number],
    },
    min: Number,
    max: Number,
    value: {
      type: [String, Number, Object],
    },
    error: String,
    iconPosition: {
      type: String,
      validator(value) {
        return value === "left" || value === "right";
      },
    },
    accept: String,
    disabled: Boolean,
    isPhone: Boolean,
    noArrows: Boolean,
    required: Boolean,
    white: Boolean,
  },
  methods: {
    focus(e) {
      if (!this.value && this.isPhone) {
        this.$emit("input", " ");
      }
      this.$emit("focus", e);
    },
    callBlur() {
      this.$refs.input.blur();
    },
    handleInput(e) {
      let value = e.target.value;
      if (this.type === "number") {
        // if (value > this.max) value = this.max;
        // if (value < this.min) value = this.min;
        //   if (value < this.min) {
        //     this.$refs.input.value = this.min.toString();
        //     this.$emit("input", this.min);
        //   } else if (value > this.max) {
        //     this.$refs.input.value = this.max.toString();
        //     this.$emit("input", this.max);
        //   } else {
        //     this.$refs.input.value = value.toString();
        //     this.$emit("input", value);
        //   }
        this.$emit("input", value);
      } else {
        this.$refs.input.value = value.toString();
        if (this.numbersOnly) {
          this.$refs.input.value = value.replace(/[^0-9]/g, "");
          this.$emit("input", value.replace(/[^0-9]/g, ""));
        } else {
          this.$emit("input", value);
        }
      }
    },
    handleKeyDown(e) {
      if (e.keyCode === 13) {
        this.$emit("enter");
      }
    },
  },
  components: { IconComponent },
  directives: {
    mask: VueMaskDirective,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/elements/input.styl"
</style>
