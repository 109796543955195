<template>
  <div class="default-modal login-modal">
    <div class="default-modal__header">
      <h3 class="default-modal__title">{{ title || "Войти по номеру" }}</h3>
      <button type="button" class="default-modal__close" @click="$emit('close')">
        <IconComponent name="close" />
      </button>
    </div>
    <div class="default-modal__body login-modal__body">
      <div class="login-modal__inputs">
        <InputComponent
          v-model="form.phone.value"
          :error="form.phone.error"
          placeholder="+7 (___) ___ - __ - __"
          mask="+7 (###) ### - ## - ##"
          is-phone
          :disabled="step === 1"
          @enter="userLogin"
        />
        <template v-if="step === 1">
          <InputComponent
            v-model="code"
            :error="codeError"
            title="Код"
            mask="####"
            autofocus
            @enter="phoneConfirm"
          />
          <span>{{ confirmTitle }}</span>
        </template>
      </div>
    </div>
    <div class="default-modal__footer login-modal__footer">
      <template v-if="step === 0">
        <button type="button" class="btn btn--md btn--main" @click="userLogin">
          <LoadingIndicator v-show="loading" name="loading" title="Отправляем код" />
          <template v-if="!loading">Получить код</template>
        </button>
        <button type="button" class="btn btn--md btn--gray-hollow" @click="showEmailLoginModal">
          Войти по email
        </button>
      </template>
      <button
        type="button"
        class="btn btn--md btn--main"
        v-if="step === 1"
        @click="phoneConfirm"
        :disabled="!(code && code.length >= 4)"
      >
        <LoadingIndicator v-show="loading" name="loading" :title="submitBtn || 'Входим'" />
        <template v-if="!loading">{{ submitBtn || "Войти" }}</template>
      </button>
    </div>
  </div>
</template>

<script>
import InputComponent from "components/inputs/InputComponent.vue";
import IconComponent from "components/IconComponent.vue";
import USER_PHONE_CONFIRM from "gql/auth/UserPhoneConfirm.graphql";
import USER_LOGIN from "gql/auth/UserLogin.graphql";
import { confirmUser, parseGqlErrors, resetErrors } from "@/utils";
import LoadingIndicator from "components/LoadingIndicator.vue";
import LoginViaEmailModal from "components/modals/components/LoginViaEmailModal.vue";

// step 0 - Ввод номера телефона
// step 1 - Ввод кода из смс
export default {
  name: "LoginModal",
  props: {
    routeAfterLogin: Object,
    initStep: Number,
    phone: [String, Number],
    title: String,
    submitBtn: String,
  },
  data() {
    return {
      step: 0,
      form: {
        phone: {
          value: null,
          error: null,
        },
      },
      code: null,
      loading: false,
      codeError: null,
      confirmTitle: "Вам поступит звонок от робота.\n Необходимо ввести 4 последние цифры входящего номера",
    };
  },
  mounted() {
    if (this.initStep) {
      this.step = this.initStep;
    }
    if (this.phone) {
      this.form.phone.value = this.phone;
    }
  },
  methods: {
    confirmUser,
    parseGqlErrors,
    resetErrors,
    userLogin() {
      if (!this.loading) {
        this.loading = true;
        this.resetErrors("form");
        this.$apollo.provider.defaultClient
          .mutate({
            mutation: USER_LOGIN,
            variables: {
              phone: this.form.phone.value?.replace(/[^\d+]/g, ""),
            },
          })
          .then(() => {
            this.loading = false;
            this.step = 1;
          })
          .catch(({ graphQLErrors }) => {
            this.loading = false;
            this.parseGqlErrors(graphQLErrors);
          });
      }
    },
    showEmailLoginModal() {
      this.$store.state._modals.push({
        component: LoginViaEmailModal,
        options: {},
      });
    },
    phoneConfirm() {
      if (!this.loading) {
        this.loading = true;
        this.$apollo.provider.defaultClient
          .mutate({
            mutation: USER_PHONE_CONFIRM,
            variables: {
              code: parseInt(this.code),
            },
          })
          .then(({ data }) => {
            this.loading = false;
            if (data && data.UserPhoneConfirm) {
              this.confirmUser({
                store: this.$store,
                apollo: this.$apollo.provider.defaultClient,
                data: data.UserPhoneConfirm,
              });
            }
            this.$store.state._modals = [];
          })
          .catch(() => {
            this.loading = false;
            this.codeError = "Введен неправильный код подтверждения";
          });
      }
    },
  },
  components: { LoadingIndicator, IconComponent, InputComponent },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/login-modal.styl"
</style>
