import "es6-promise/auto";
import createApp from "./app";
import LoginModal from "components/modals/components/LoginModal.vue";
import { confirmUser } from "./utils";
import USER_EMAIL_CONFIRM from "gql/auth/UserEmailConfirm.graphql";
import PRODUCTS from "gql/queries/products.graphql";

const { app, router, store, apollo } = createApp(window.__INITIAL_STATE__._env);

if (window.__INITIAL_STATE__) {
  store.replaceState(window.__INITIAL_STATE__);
  store.state._window_size = window.innerWidth;
  store.state._env = window.__INITIAL_STATE__._env || {};
  store.state.apollo_token = window.__INITIAL_STATE__.apollo_token || undefined;
}

router.onReady(async () => {
  // if (!store.state.apollo_token || !store.state.auth.user) {
  //   if (localStorage.cartItems) {
  //     let cart = JSON.parse(localStorage.cartItems);
  //     let ids = cart.map((i) => i.item?.id);
  //     if (ids && ids.length) {
  //       await apollo.defaultClient
  //         .mutate({
  //           mutation: PRODUCTS,
  //           variables: {
  //             ids: ids,
  //           },
  //         })
  //         .then(({ data }) => {
  //           if (data.products && data.products.length) {
  //             data.products.forEach((p) => {
  //               cart.forEach((c) => {
  //                 if (c.item.id === p.id) {
  //                   c.item = p;
  //                 }
  //               });
  //             });
  //           }
  //           localStorage.cartItems = JSON.stringify(cart);
  //           store.state.auth.cart = cart;
  //         });
  //     }
  //   }
  // }

  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get("no_auth") === "1") {
    store.state._modals.push({
      component: LoginModal,
    });
    urlParams.delete("no_auth");
    history.replaceState(null, "", location.pathname + urlParams);
  }
  if (urlParams.get("register_confirm_token")) {
    apollo.defaultClient
      .mutate({
        mutation: USER_EMAIL_CONFIRM,
        variables: {
          token: urlParams.get("register_confirm_token"),
        },
      })
      .then(({ data }) => {
        if (data && data.UserEmailConfirm) {
          confirmUser({ store, apollo, data: data.UserEmailConfirm });
        }
      });
    urlParams.delete("register_confirm_token");
    history.replaceState(null, "", location.pathname + urlParams);
  }
  router.beforeEach((to, from, next) => {
    if (to.meta.authRequired && !store.state.auth.user) {
      store.state._modals.push({
        component: LoginModal,
        options: {
          routeAfterLogin: {
            name: to.name,
            params: to.params,
            hash: to.hash,
          },
        },
      });
      next(false);
    } else {
      next();
    }
  });
  router.beforeResolve((to, from, next) => {
    const matched = router.getMatchedComponents(to);
    const asyncDataHooks = matched.map((c) => c.asyncData).filter((_) => _);
    if (!asyncDataHooks.length) {
      return next();
    }
    return Promise.all(asyncDataHooks.map((hook) => hook({ store, route: to, apollo })))
      .then(() => {
        next();
      })
      .catch(next);
  });
  app.$mount("#app");
});

if (location.protocol === "https:" && navigator.serviceWorker) {
  navigator.serviceWorker.register("/service-worker.js").then((value) => {
    console.log(value);
  });
}
