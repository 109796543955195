<template>
  <header class="header">
    <HeaderTopComponent class="desktop" />
    <HeaderMainComponent />
  </header>
</template>

<script>
import HeaderTopComponent from "components/header/components/HeaderTopComponent.vue";
import HeaderMainComponent from "components/header/components/HeaderMainComponent.vue";

export default {
  name: "HeaderComponent",
  components: {
    HeaderMainComponent,
    HeaderTopComponent,
  },
};
</script>

<style lang="stylus">
.header {
  .desktop {
    +below(1024px) {
      display none
    }
  }
  .mobile{
    +above(1025px) {
      display none
    }
  }
}
</style>
