<template>
  <div id="app">
    <vue-progress-bar />
    <HeaderComponent />
    <main class="main">
      <Transition mode="out-in" name="page">
        <RouterView :key="$route.fullPath" />
      </Transition>
    </main>
    <FooterComponent />
    <Transition name="bounce">
      <ModalComponent />
    </Transition>
    <Transition name="slide">
      <MenuComponent />
    </Transition>
    <Notifications position="top right" />
    <CookiesComponent />
  </div>
</template>

<script>
import ModalComponent from "components/modals/index.vue";
import HeaderComponent from "components/header/index.vue";
import FooterComponent from "components/footer/index.vue";
import MenuComponent from "components/menus/index.vue";
import CookiesComponent from "components/CookiesComponent.vue";

export default {
  name: "App",
  created() {
    // Полоска загрузки
    this.$Progress.start();
    this.$router.beforeEach((to, from, next) => {
      if (to.meta.progress !== undefined) {
        const meta = to.meta.progress;
        this.$Progress.parseMeta(meta);
      }
      this.$Progress.start();
      next();
    });
    this.$router.afterEach(() => {
      this.$Progress.finish();
    });
  },
  mounted() {
    this.$Progress.finish();
    // если пользователь не авторизован, то берем товары в корзине из localStorage
    if (!this.isRegistered) {
      this.$store.state.auth.cart = localStorage.cartItems
        ? JSON.parse(localStorage.cartItems).filter((c) => c.item)
        : [];
    }
  },
  watch: {
    "$store.state.auth.cart"() {
      // обновляем localStorage при каждом изменении state.auth.cart
      localStorage.cartItems = this.$store.state.auth.cart ? JSON.stringify(this.$store.state.auth.cart) : [];
    },
  },
  computed: {
    isRegistered() {
      return this.$store.state.apollo_token || this.$store.state.auth.user;
    },
  },
  components: {
    CookiesComponent,
    MenuComponent,
    ModalComponent,
    HeaderComponent,
    FooterComponent,
  },
  metaInfo: {
    titleTemplate: "%s | FinikMarket",
    meta: [
      {
        vmid: "description",
        name: "description",
        content: "Интернет магазин Финикмаркет",
      },
      {
        vmid: "og:title",
        name: "og:title",
        content: "Финикмаркет",
      },
      {
        vmid: "og:description",
        name: "og:description",
        content: "Интернет магазин Финикмаркет",
      },
    ],
  },
};
</script>

<style lang="stylus">
@import 'styles/style.styl';
</style>
