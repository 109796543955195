<template>
  <div class="default-modal login-modal">
    <div class="default-modal__header default-modal__header--center">
      <button type="button" class="default-modal__back" @click="$emit('close')">
        <IconComponent name="arrow-left-2" />
      </button>
      <h3 class="default-modal__title">Войти по email</h3>
      <button type="button" class="default-modal__close" @click="$emit('closeAll')">
        <IconComponent name="close" />
      </button>
    </div>
    <div class="default-modal__body login-modal__body">
      <div class="login-modal__inputs">
        <InputComponent
          v-model="form.email.value"
          :error="form.email.error"
          placeholder="Электронная почта"
          modified
        />
        <InputComponent
          v-model="form.password.value"
          :errors="form.password.error"
          :type="form.password.show ? 'text' : 'password'"
          title="Ваш пароль"
          @submit="form.password.show = !form.password.show"
          modifed
        >
          <template v-slot:action>
            <IconComponent v-if="form.password.show" name="eye-line" />
            <IconComponent v-else name="eye-close-line" />
          </template>
        </InputComponent>
      </div>
    </div>
    <div class="default-modal__footer login-modal__footer">
      <button type="button" class="btn btn--md btn--main" @click="login">
        <IconComponent v-if="loading" name="loading" />
        <template v-else>Войти</template>
      </button>
      <button type="button" class="btn btn--md btn--gray-hollow" @click="showResetPasswordModal">
        Забыли пароль?
      </button>
      <button type="button" class="btn btn--md btn--green-hollow" @click="showRegisterModal">
        Регистрация
      </button>
    </div>
  </div>
</template>

<script>
import InputComponent from "components/inputs/InputComponent.vue";
import IconComponent from "components/IconComponent.vue";
import RegisterModal from "components/modals/components/RegisterModal.vue";
import ResetPasswordModal from "components/modals/components/ResetPasswordModal.vue";
import USER_LOGIN_VIA_EMAIL from "gql/auth/UserLoginViaEmail.graphql";
import { confirmUser } from "@/utils";

export default {
  name: "LoginViaEmailModal",
  props: {
    routeAfterLogin: Object,
  },
  data() {
    return {
      number: null,
      loading: false,
      error: null,
      codeError: null,
      form: {
        email: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        password: {
          value: undefined,
          message: undefined,
          error: undefined,
          show: false,
        },
      },
    };
  },
  methods: {
    showPass() {
      this.form.password.show = !this.form.password.show;
    },
    confirmUser,
    login() {
      if (!this.loading) {
        this.loading = true;
        this.$apollo.provider.defaultClient
          .mutate({
            mutation: USER_LOGIN_VIA_EMAIL,
            variables: {
              email: this.form.email.value,
              password: this.form.password.value,
            },
          })
          .then(({ data }) => {
            this.loading = false;
            if (data && data.UserLoginViaEmail) {
              this.confirmUser({
                store: this.$store,
                apollo: this.$apollo.provider.defaultClient,
                data: data.UserLoginViaEmail,
              });
              this.$store.state._modals = [];
            }
          })
          .catch(({ graphQLErrors }) => {
            this.loading = false;
            this.parseGqlErrors(graphQLErrors);
          });
      }
    },
    parseGqlErrors(graphQLErrors) {
      graphQLErrors.forEach((err) => {
        if (err.extensions.category === "validation") {
          Object.keys(err.extensions.validation).forEach((key) => {
            if (this.form[key]) {
              this.form[key].error = err.extensions.validation[key][0];
            }
          });
        }
      });
    },
    showRegisterModal() {
      this.$store.state._modals.push({
        component: RegisterModal,
        options: {},
      });
    },
    showResetPasswordModal() {
      this.$store.state._modals.push({
        component: ResetPasswordModal,
        options: {},
      });
    },
  },
  components: { IconComponent, InputComponent },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/login-modal.styl"
</style>
