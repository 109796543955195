<template>
  <div class="default-modal login-modal">
    <div class="default-modal__header default-modal__header--center">
      <button type="button" class="default-modal__back" @click="$emit('close')">
        <IconComponent name="arrow-left-2" />
      </button>
      <h3 class="default-modal__title">Регистрация</h3>
      <button type="button" class="default-modal__close" @click="$emit('closeAll')">
        <IconComponent name="close" />
      </button>
    </div>
    <div class="default-modal__body login-modal__body">
      <div class="login-modal__inputs">
        <InputComponent
          v-model="form.email.value"
          :error="form.email.error"
          title="Email"
          placeholder="Электронная почта"
          modified
        />
        <InputComponent
          v-model="form.password.value"
          :errors="form.password.error"
          :type="form.password.show ? 'text' : 'password'"
          title="Пароль"
          placeholder="Ваш пароль"
          @submit="form.password.show = !form.password.show"
          modifed
        >
          <template v-slot:action>
            <IconComponent v-if="form.password.show" name="eye-line" />
            <IconComponent v-else name="eye-close-line" />
          </template>
        </InputComponent>
        <InputComponent
          v-model="form.password_confirmation.value"
          :errors="form.password_confirmation.error"
          :type="form.password_confirmation.show ? 'text' : 'password'"
          title="Подтвердите пароль"
          placeholder="Ваш пароль"
          @submit="form.password_confirmation.show = !form.password_confirmation.show"
          modifed
        >
          <template v-slot:action>
            <IconComponent v-if="form.password_confirmation.show" name="eye-line" />
            <IconComponent v-else name="eye-close-line" />
          </template>
        </InputComponent>
      </div>
    </div>
    <div class="default-modal__footer login-modal__footer">
      <button type="button" class="btn btn--md btn--main" @click="submit">
        <IconComponent v-if="loading" name="loading" />
        <template v-else>Зарегистрироваться</template>
      </button>
    </div>
  </div>
</template>

<script>
import InputComponent from "components/inputs/InputComponent.vue";
import IconComponent from "components/IconComponent.vue";
import AlertModal from "components/modals/components/AlertModal.vue";
import USER_REGISTER from "gql/auth/UserRegister.graphql";

export default {
  name: "RegisterModal",
  data() {
    return {
      number: null,
      loading: false,
      error: null,
      codeError: null,
      form: {
        name: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        email: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        password: {
          value: undefined,
          message: undefined,
          error: undefined,
          show: false,
        },
        password_confirmation: {
          value: undefined,
          message: undefined,
          error: undefined,
          show: false,
        },
      },
    };
  },
  methods: {
    submit() {
      if (!this.loading) {
        this.loading = true;
        this.$apollo.provider.defaultClient
          .mutate({
            mutation: USER_REGISTER,
            variables: {
              name: this.form.name.value,
              email: this.form.email.value,
              password: this.form.password.value,
              password_confirmation: this.form.password_confirmation.value,
            },
          })
          .then(({ data }) => {
            if (data && data.UserRegister) {
              this.showAlertModal({ title: "Регистрация", message: data.UserRegister.message });
            }
            this.loading = false;
          })
          .catch(({ graphQLErrors }) => {
            this.loading = false;
            this.parseGqlErrors(graphQLErrors);
          });
      }
    },
    showAlertModal(options) {
      this.$store.state._modals.push({
        component: AlertModal,
        options: options,
      });
    },
    parseGqlErrors(graphQLErrors) {
      graphQLErrors.forEach((err) => {
        if (err.extensions.category === "validation") {
          Object.keys(err.extensions.validation).forEach((key) => {
            if (this.form[key]) {
              this.form[key].error = err.extensions.validation[key][0];
            }
          });
        }
      });
    },
  },
  components: { IconComponent, InputComponent },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/login-modal.styl"
</style>
