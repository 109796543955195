import Vue from "vue";
import Vuex from "vuex";
import actions from "./actions";

Vue.use(Vuex);
export default function createStore(env) {
  return new Vuex.Store({
    actions,
    state() {
      return {
        _env: env,
        _modals: [],
        _menus: [],
        _status: {},
        _types: {
          // способы доставки
          PICKUP: 1, // Самовывоз
          COURIER_DELIVERY: 2, // Курьерская доставка
          // способы оплаты
          ONLINE_CARD_PAY: 1, // Оплата картой онлайн
          OFFLINE_CASH_PAY: 3, // Наличными при получении
          // коды контактных данных
          ADDRESS: 1,
          PHONE: 2,
          SOCIAL: 3,
          EMAIL: 4,
          // коды баннеров
          BANNER_MAIN: 1, // на главную
          BANNER_CATALOG_SET: 2, // на каталог наборов
          BANNER_PAGE_SET: 3, // на страницу набора
          BANNER_SHARES: 4, // на страницу акции
          // коды документов/страниц (feedback)
          ORDERING_RULES: 1, // Правила оформления заказа
          HELP: 2, // Помощь
          REFUND: 3, // Возврат
          PAYMENT_METHOD: 4, // Способы оплаты
          COOPERATION: 5, // Сотрудничество
          OFFER: 6, // Публичная оферта
          TERMS: 7, // Условия использования
          POLICY: 8, // Политика конфиденциальности
          PERSONAL_DATA: 9, // Обработка персональных данных
          FOR_BUSINESS: 10, // Для бизнеса
          // коды заказов
          CODE_ORDER_CREATED: 1, // Создан ожидает оплаты
          CODE_ORDER_SUCCESS: 2, // оплачено обработка заказа
          CODE_ORDER_CANCELED: 3, // отмена оплаты
          CODE_ORDER_FAILED: 4, // оплата провалена
          CODE_ORDER_IN_PROCESS: 5, // обработан ожидает отправки
          CODE_ORDER_IN_DELIVERY: 6, // в службе доставки
          CODE_ORDER_DELIVERED: 7, // в службе доставки
          CODE_ORDER_OFFLINE_PAY: 8, // оплата при получении
        },
        loading: true, // загрузка страницы
        global: {
          categories: [], // главные категории
          feedback: {}, // информация о магазине, контакты
          pages: [], // страницы
        },
        auth: {
          user: null,
          bonus: null, // бонусы
          cart: [], // товары в корзине
          orders: [], // все заказы, чтобы подсчитать сумму
          orders_paginate: {}, // заказы в лк
          deliveries: [], // способы доставки
          favorites: [], // все избранные, чтобы вывести в хедере
          favorites_ids: [], // id всех избранных товаров
          // favorites_paginate: {}, // избранные в лк
          addresses: [], // адреса доставки
        },
        shares_page: [], // Акции
        share_item: {
          banners: [],
          products: [],
        }, // Акция item
        search_page: {},
        home_page: {
          sets_first_block: [],
          sets_second_block: [],
          banners: [],
          what_buy: [], // что покупают
          bestseller: [], // хиты продаж
        },
        set_page: {
          sets_item: {},
          banners: [],
        },
        sets_page: {
          sets: [],
          banners: [],
        },
        vacancies_page: {
          vacancies: [],
        },
        product_page: {
          product_info: {},
          shares: [],
          count: 0,
        },
        catalog_page: {
          category: {},
          products: {},
        },
        cart_page: {
          what_buy_products: [],
        },
        faq_page: {},
        page_thanks: {
          products: [],
        },
        page_404: {
          sets: [],
        },
      };
    },
  });
}
