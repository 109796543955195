<template>
  <div class="default-modal alert-modal" :class="{ 'alert-modal--center': center }">
    <div class="default-modal__header alert-modal__header">
      <h3 class="default-modal__title" v-if="title">{{ title }}</h3>
      <button type="button" class="default-modal__close" @click="$emit('close')">
        <IconComponent name="close" />
      </button>
    </div>
    <div class="default-modal__body">
      <div class="alert-modal__message">
        {{ message || "Успешно!" }}
      </div>
    </div>
    <div class="default-modal__footer alert-modal__footer">
      <button type="button" :class="`btn btn--md ${closeBtnClass}`" @click="$emit('closeAll')">
        {{ closeBtnTitle }}
      </button>
      <button type="button" :class="`btn btn--md ${confirmBtnClass}`" @click="confirm" v-if="isConfirm">
        {{ confirmBtnTitle }}
      </button>
    </div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";

export default {
  name: "AlertModal",
  props: {
    title: String,
    message: String,
    isConfirm: Boolean,
    center: Boolean,
    closeBtnTitle: {
      type: String,
      default: "Закрыть",
    },
    confirmBtnTitle: {
      type: String,
      default: "Подтвердить",
    },
    closeBtnClass: {
      type: String,
      default: "btn--main",
    },
    confirmBtnClass: {
      type: String,
      default: "btn--main",
    },
    callback: Function,
  },
  methods: {
    confirm() {
      if (this.callback) {
        this.callback();
      }
      this.$emit("close");
    },
  },
  components: { IconComponent },
};
</script>

<style lang="stylus">
.alert-modal {
  width 100%
  max-width 382px

  &--center &__header {
    justify-content center
  }

  &__message {
    width 100%
    text-align center
    white-space pre-line
  }

  & &__footer {
    flex-direction row

    .btn {
      width 100%
      justify-content center
    }
  }
}
</style>
