<template>
  <div class="small-footer">
    <div class="container-padding">
      <div class="small-footer__inner">
        <span>2022 © ООО «Финикмаркет».</span>
        <div class="small-footer__documents">
          <router-link :to="{ name: 'document', params: { id: types.OFFER } }">
            Публичная оферта.
          </router-link>
          <router-link :to="{ name: 'document', params: { id: types.TERMS } }">
            Условия использования.
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SmallFooterComponent",
  computed: {
    types() {
      return this.$store.state._types;
    },
  },
};
</script>

<style lang="stylus">
.small-footer {
  display flex
  background var(--gray)
  align-items: center;
  min-height: 80px;

  &__inner {
    display flex
    align-items center
    justify-content space-between
    gap 15px
    +below(860px) {
      flex-direction column
      padding 30px 0
    }
    +below(540px) {
      align-items flex-start
    }

    span{
      font-weight: 400;
      font-size: 0.875em;
      line-height: 20px;
      color: var(--dark);
    }
  }

  &__documents{
    display flex
    flex-wrap wrap
    column-gap 15px
    text-decoration underline
    a{
      text-decoration-line: underline;
      color: var(--dark);
    }
  }
}
</style>
