import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default function createRouter() {
  const routes = [
    {
      path: "/",
      name: "home",
      component: () => import("./views/home/index.vue"),
    },
    {
      path: "/shares",
      name: "shares",
      component: () => import("./views/shares/list/index.vue"),
    },
    {
      path: "/shares/:id",
      name: "share",
      component: () => import("./views/shares/open/index.vue"),
    },
    {
      path: "/product/:id",
      name: "product",
      component: () => import("./views/product/index.vue"),
    },
    {
      path: "/sets",
      name: "sets",
      component: () => import("./views/sets/list/index.vue"),
    },
    {
      path: "/sets/:id?",
      name: "set",
      component: () => import("./views/sets/open/index.vue"),
    },
    {
      path: "/faq",
      name: "faq",
      component: () => import("./views/faq/index.vue"),
    },
    {
      path: "/vacancies",
      name: "vacancies",
      component: () => import("./views/vacancies/index.vue"),
    },
    {
      path: "/catalog",
      name: "catalog-main",
      redirect: "/",
    },
    {
      path: "/catalog/:id",
      name: "catalog",
      component: () => import("./views/catalog/index.vue"),
    },
    {
      path: "/document/:id",
      name: "document",
      component: () => import("./views/document/index.vue"),
    },
    {
      path: "/search",
      name: "search",
      component: () => import("./views/search/index.vue"),
    },
    {
      path: "/cart",
      name: "cart",
      component: () => import("./views/cart/index.vue"),
    },
    {
      path: "/user/:link?",
      name: "user",
      component: () => import("./views/user/index.vue"),
      meta: {
        authRequired: true,
      },
    },
    {
      path: "/recovery",
      name: "recovery",
      component: () => import("./views/recovery/index.vue"),
    },
    {
      path: "/thank-you",
      name: "thank-you",
      component: () => import("./views/ThankYou.vue"),
      meta: {
        showSearch: false,
      },
      // meta: {
      //   authRequired: true,
      // },
    },
    {
      path: "*",
      name: "notfound",
      component: () => import("./views/PageNotFound.vue"),
    },
  ];
  return new Router({
    mode: "history",
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      }
      if (to.hash) {
        return {
          selector: to.hash,
          behavior: "smooth",
        };
      }
      return { x: 0, y: 0 };
    },
    fallback: true,
    routes,
  });
}
