<template>
  <div class="default-modal login-modal">
    <div class="default-modal__header default-modal__header--center">
      <button type="button" class="default-modal__back" @click="$emit('close')">
        <IconComponent name="arrow-left-2" />
      </button>
      <h3 class="default-modal__title">Забыли пароль?</h3>
      <button type="button" class="default-modal__close" @click="$emit('closeAll')">
        <IconComponent name="close" />
      </button>
    </div>
    <div class="default-modal__body login-modal__body">
      <div class="login-modal__inputs">
        <span> Введите свой email — мы отправим на него ссылку для сброса пароля </span>
        <InputComponent
          v-model="form.email.value"
          :error="form.email.error"
          title="Email"
          placeholder="Электронная почта"
          modified
        />
      </div>
    </div>
    <div class="default-modal__footer login-modal__footer">
      <button @click="submit" type="button" class="btn btn--md btn--main">Отправить</button>
    </div>
  </div>
</template>

<script>
import InputComponent from "components/inputs/InputComponent.vue";
import IconComponent from "components/IconComponent.vue";
import AlertModal from "components/modals/components/AlertModal.vue";
import FORGOT from "@/graphql/auth/UserForgotPassword.graphql";

export default {
  name: "ResetPasswordModal",
  props: {
    routeAfterLogin: Object,
  },
  data() {
    return {
      loading: false,
      form: {
        email: {
          value: undefined,
          error: undefined,
        },
      },
    };
  },
  methods: {
    resetErrors() {
      Object.keys(this.form).forEach((key) => {
        this.form[key].errors = [];
      });
    },
    parseGqlErrors(graphQLErrors) {
      graphQLErrors.forEach((err) => {
        if (err.extensions.category === "validation") {
          Object.keys(err.extensions.validation).forEach((key) => {
            if (this.form[key]) {
              this.form[key].errors.push(err.extensions.validation[key][0]);
            }
          });
        }
      });
    },
    showAlertModal(options) {
      this.$store.state._modals.push({
        component: AlertModal,
        options: options,
      });
    },
    submit() {
      this.loading = true;
      this.resetErrors();
      this.$apollo
        .mutate({
          mutation: FORGOT,
          variables: {
            email: this.form.email.value,
          },
        })
        .then(({ data }) => {
          if (data && data.UserForgotPassword) {
            this.showAlertModal({ title: "Сброс пароля", message: data.UserForgotPassword.message });
          }
          this.loading = false;
        })
        .catch(({ graphQLErrors }) => {
          this.loading = false;
          this.parseGqlErrors(graphQLErrors);
        });
    },
  },
  components: {
    IconComponent,
    InputComponent,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/login-modal.styl"
</style>
