<template>
  <div class="catalog-component" v-if="activeCategory">
    <h3 class="catalog-component__title">Каталог</h3>
    <div class="catalog-component__body">
      <div class="catalog-component__list">
        <a
          v-for="(category, i) in categories"
          :key="i"
          @click="catalogLink(category)"
          @mouseover="setCategory(category)"
          :class="{
            'catalog-component__item--active': JSON.stringify(activeCategory) === JSON.stringify(category),
          }"
          class="catalog-component__item"
        >
          <ImgComponent
            class="catalog-component__item-img"
            :img="category.head_img"
            width="100"
            height="100"
          />
          <span class="catalog-component__item-title" v-if="category">{{ category.title }}</span>
          <IconComponent name="arrow-right-s-line" />
        </a>
      </div>
      <div class="catalog-component__content">
        <h4 class="catalog-component__content-title" v-if="activeCategory">
          {{ activeCategory.title }}
        </h4>
        <div class="catalog-component__cards">
          <router-link
            :to="{ name: 'catalog', params: { id: child.id } }"
            v-for="(child, i) in activeCategory.childs"
            :key="i"
            class="catalog-component__card"
          >
            <ImgComponent
              :img="child.head_img"
              class="catalog-component__card-img"
              width="100"
              height="100"
            />
            <span class="catalog-component__card-title" v-if="child">{{ child.title }}</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImgComponent from "components/ImgComponent.vue";
import IconComponent from "components/IconComponent.vue";

export default {
  name: "CatalogComponent",
  data() {
    return {
      activeCategory: null,
    };
  },
  mounted() {
    this.setCategory(this.categories[0]);
  },
  computed: {
    categories() {
      return this.$store.state.global.categories.filter((i) => i.childs && i.childs.length) || [];
    },
  },
  methods: {
    catalogLink(category) {
      this.$router.push({ name: "catalog", params: { id: category.id } });
    },
    setCategory(category) {
      this.activeCategory = category;
    },
  },
  components: {
    IconComponent,
    ImgComponent,
  },
};
</script>

<style lang="stylus">
.catalog-component {
  padding 32px 40px
  display flex
  flex-direction column
  gap 24px
  height 100%
  +below(1025px) {
    display none
  }

  &--open {
    opacity 1
    visibility visible
  }

  &__title {
    font-size 1.5em
    font-weight 500
    line-height: 36px;
    margin 0
  }

  &__body {
    display flex
    gap 24px
    height 100%
  }

  &__list {
    width 100%
    max-width 312px
    max-height 694px
    overflow-x auto
    display flex
    flex-direction column
    gap 8px
    padding-left 8px
    direction rtl
  }

  &__item {
    flex-shrink 0
    height 54px
    display flex
    align-items center
    padding 12px
    gap 4px
    border-radius 16px
    border: 1px solid var(--border-color-2);
    direction ltr
    transition var(--transition)

    &--active {
      background var(--gray-100)
    }

    &:hover {
      background var(--gray-100)
    }

    &-img {
      flex-shrink 0
      width 28px
      height: 28px
      border-radius 50%
      object-fit cover
      object-position center
      overflow hidden
    }

    &-title {
      flex-grow 1
      line-height 1.3
    }

    .icon {
      width 20px
      height 20px
    }
  }

  &__content {
    width 100%
    display flex
    flex-direction column
    gap 24px
  }

  &__content-title {
    font-size 1.25em
    line-height: 32px;
    font-weight 500
    margin 0

    a {
      color var(--body-color)
      transition var(--transition)

      &:hover {
        color var(--main)
      }
    }
  }

  &__cards {
    width 100%
    display grid
    grid-template-columns repeat(3, 1fr)
    grid-gap 24px
    overflow auto
    +below(1250px) {
      grid-template-columns 1fr 1fr
      grid-gap 16px
    }
  }

  &__card {
    width 100%
    height 76px
    display flex
    align-items center
    padding 8px 12px
    gap 8px
    border 1px solid var(--border-color-2)
    border-radius 16px
    cursor pointer
    color var(--gray-900)
    transition var(--transition)

    &:hover {
      background var(--gray-100)
      border-color var(--gray-100)
    }
  }

  &__card-img {
    flex-shrink 0
    width: 60px
    height: 60px
    border-radius 16px
    //object-fit contain
    background var(--white)
  }

  &__card-title {
    font-weight 500
    line-height: 22px;
    word-break: break-word;
  }

  &--open-all {
    margin-top auto
  }
}
</style>
