<template>
  <div class="default-modal addresses-modal">
    <div class="default-modal__header addresses-modal__header">
      <h2 class="default-modal__title">Выберите адрес</h2>
      <button class="default-modal__close" @click="$emit('closeAll')">
        <IconComponent name="close" />
      </button>
    </div>
    <div v-if="addresses && addresses.length" class="default-modal__body addresses-modal__body">
      <AddressComponent
        @toggleAddress="toggleAddress"
        @deleteAddress="deleteAddress"
        @togglePopper="togglePopper"
        @closePopper="closePopper"
        :activeAddress="activeAddress && activeAddress.id === address.id"
        :activePopper="activePopper && activePopper.id === address.id"
        v-for="(address, i) in addresses"
        :key="i"
        :data="address"
      />
    </div>
    <span v-else class="empty-block">Адреса отсутствуют</span>
    <div class="addresses-modal__footer">
      <button :disabled="!activeAddress" @click="addressActive" class="btn btn--md btn--main">Выбрать</button>
      <button @click="showAddressModal" class="btn addresses-modal__button">Добавить новый адрес</button>
    </div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import LoginModal from "components/modals/components/LoginModal.vue";
import AddressModal from "components/modals/components/AddressModal.vue";
import AddressComponent from "components/AddressComponent.vue";
import ADDRESS_DELETE from "gql/mutations/AddressDelete.graphql";
import ADDRESS_ACTIVE from "gql/mutations/AddressActive.graphql";

export default {
  name: "AddressesModal",
  data() {
    return {
      loading: false,
      activePopper: null,
      activeAddress: null,
    };
  },
  created() {
    this.activeAddress = this.$store.state.auth.addresses.find((item) => item.is_active);
  },
  computed: {
    isRegistered() {
      return !!this.$store.state.apollo_token || this.user;
    },
    addresses() {
      return this.$store.state.auth.addresses;
    },
  },
  methods: {
    togglePopper(data) {
      this.activePopper = data;
    },
    closePopper() {
      this.activePopper = null;
    },
    toggleAddress(address) {
      this.activeAddress = address;
    },
    addressActive() {
      if (!this.loading) {
        this.loading = true;
        this.$apollo.provider.defaultClient
          .mutate({
            mutation: ADDRESS_ACTIVE,
            variables: {
              id: this.activeAddress.id,
            },
            context: {
              headers: {
                Authorization: "Bearer " + this.$store.state.apollo_token,
              },
            },
          })
          .then(() => {
            this.loading = false;
            this.$store.state._modals = [];
            this.$store.dispatch("GET_ADDRESSES", {
              apollo: this.$apollo,
            });
          });
      }
    },
    deleteAddress(id) {
      if (!this.loading) {
        this.loading = true;
        this.$apollo.provider.defaultClient
          .mutate({
            mutation: ADDRESS_DELETE,
            variables: {
              id: id,
            },
            context: {
              headers: {
                Authorization: "Bearer " + this.$store.state.apollo_token,
              },
            },
          })
          .then(() => {
            this.loading = false;
            // this.activeAddress = null;
            this.$store.dispatch("GET_ADDRESSES", {
              apollo: this.$apollo,
            });
            if (this.activeAddress.id === id) {
              this.activeAddress = null;
            }
            // this.activeAddress?.id === id ? null : this.activeAddress;
          });
      }
    },
    showAddressModal() {
      this.$store.state._modals.push({
        component: this.isRegistered ? AddressModal : LoginModal,
        options: {
          back: true,
        },
      });
    },
  },
  components: { AddressComponent, IconComponent },
};
</script>

<style lang="stylus">
.addresses-modal {
  //min-width 600px
  max-width 600px

  & &__header {
    flex-direction column
    align-items flex-start
    gap: 15px
  }

  &__body {
    gap 10px
  }

  &__footer {
    display flex
    align-items center
    gap 20px
    margin-top 15px
  }

  &__button {
    color var(--main)
    font-size 1rem
    font-weight 600
  }
}
</style>
